html,
body{
    width: 100%;
    height: 100%;
    padding: 0px;
    margin: 0px;
    bottom: 0px;
    top: 0px;
}

.container{
    width: 100%;
    height: 100%;
    background-color: black;
}
